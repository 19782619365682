<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click.prevent="updateRoute('messages')">
            <ion-icon :ios="mail" :md="mail"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Settings</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <!-- Profile Feed -->
        <div class="theme-light ion-padding-bottom">
          <!-- Location -->
          <ion-item button detail color="light" @click.prevent="updateRoute('profile-location')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>My Location</ion-label>
          </ion-item>
          <!-- Genres -->
          <ion-item button detail color="light" @click.prevent="updateRoute('profile-genres')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>My Genres</ion-label>
          </ion-item>
          <!-- Notifications -->
          <ion-item button detail color="light" @click.prevent="updateRoute('profile-notification-settings')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Notifications</ion-label>
          </ion-item>
          <!-- Security -->
          <ion-item button detail color="light" @click.prevent="updateRoute('profile-security')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Security</ion-label>
          </ion-item>
          <!-- Security -->
          <ion-item button detail color="light" @click.prevent="updateRoute('profile-status')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Account Status</ion-label>
          </ion-item>
          <!-- Admins Only -->
          <ion-item v-if="isAdmin === true" button detail color="light" @click.prevent="updateRoute('admin')">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-label>Admin</ion-label>
          </ion-item>
          <!-- Sign Out -->
          <SignOut />
        </div>
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="profile-settings" />
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel, IonTitle } from '@ionic/vue';
import { chevronBack, mail } from 'ionicons/icons';
import { defineComponent, computed, ref } from 'vue';
import SignOut from '@/components/SignOut.vue';
import { useStore } from "vuex";
import doAuth from '../services/auth';
import Tabs from '../components/Tabs.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ProfileSettings',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel, SignOut, Tabs, IonTitle
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const authUser = computed(() => store.state.authUser);
    const isAdmin = ref(true);

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      authUser, store, router, updateRoute, chevronBack, mail, goBack, isAdmin
    }
  }
});
</script>