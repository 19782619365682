<template>
	<div>
		<!-- Errors -->
		<div v-if="isError === true">
			<div v-for="error in errors" :key="error">
				<div v-for="el in error" :key="el">
					<ion-item>
						<div tabindex="0"></div><!-- bug fix for safari 14 -->
						<ion-text color="danger">{{el}}</ion-text>
					</ion-item>
				</div>
			</div>
		</div>
		<ion-button expand="full" size="small" color="primary" @click.prevent="handleLogout()">Sign Out</ion-button>
	</div>
</template>

<script>
import { IonButton, IonItem, IonText, loadingController } from '@ionic/vue';
import apiClient from '../services/api';
import { defineComponent } from 'vue';
import { useStore } from "vuex";

export default defineComponent( {
	components: { IonButton, IonItem, IonText },
	name: 'SignOut',
	data() {
		return {
			isError: false,
			errors: null,
		}
	},
	setup() {
		const store = useStore();
		const timeout = { default: 3000 }
		//const cookies = document.cookie.split(";");

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() { loading.dismiss() }, timeout);
		}

		function handleLogout() {
			presentLoading()
			// eslint-disable-next-line no-unused-vars
			apiClient.get('/sanctum/csrf-cookie').then(response => {
				// eslint-disable-next-line no-unused-vars
				apiClient.post('/logout', {}).then(response => {
					console.log(response)	
					
					store.commit('setIsAuth', false);
					store.commit('setAuthToken', null);
					store.commit('setAuthUser', []);
					store.commit('setActiveUser', false);
					localStorage.removeItem('isAuth');
					localStorage.removeItem('authUser');
					localStorage.removeItem('authToken')
					localStorage.removeItem('activeUser')	

					window.location.reload(true)

					/*for (var i = 0; i < cookies.length; i++) {
						var cook = cookies[i];
						var eqPos = cook.indexOf("=");
						var name = eqPos > -1 ? cook.substr(0, eqPos) : cook;
						document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
					}*/

				}).catch(error => {
					console.log(error)	
					// credentials didn't match
					if(error.response.status === 422) {
						this.isError = true
						this.errors = error.response.data.errors
					}
					if(error.response.status === 404) {
						this.isError = true
						this.errors = error.response.data.errors
					}
				}); 
			});
		}

		return { handleLogout, presentLoading }
	}
});
</script>